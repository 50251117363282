import React, { useState } from "react"
// import { firebase } from "@firebase/app"
// import "@firebase/firestore"
import { StaticImage } from "gatsby-plugin-image"
// if (!firebase.apps.length) {
//   firebase.initializeApp({
//     apiKey: process.env.API_KEY,
//     projectId: "lofbergs-kamppis",
//     appId: process.env.APP_ID,
//   })
// }
const Form = () => {
  const [data, setData] = useState([])
  const [submit, setSubmit] = useState(false)
  const [error, setError] = useState(false)
  // var db = firebase.firestore()
  const handleSubmit = e => {
    e.preventDefault()
    console.log("handle submit")
    setData(data)

    // db.settings({
    //   timestampsInSnapshots: true,
    // })
    // validate
    // db.collection("vkvk-osallistujat")
    //   .add({
    //     ...data,
    //     pvm: firebase.firestore.Timestamp.fromDate(new Date()),
    //   })
    //   .then(docRef => {
    //     console.log("Document written with ID: ", docRef.id)
    //     setSubmit(true)
    //   })
    //   .catch(error => {
    //     console.error("Error adding document: ", error)
    //   })
    fetch(process.env.GATSBY_API_URL, {
      method: "POST",
      mode: "no-cors",
      referrer: "no-referrer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify(data),
    })
      .then(res => {
        setSubmit(true)
        console.log(res)
      })
      .catch(err => {
        setError(true)
        console.error(err)
      })
  }

  return (
    <div className="formPage">
      <br />

      {!submit ? (
        <form onSubmit={e => handleSubmit(e)} className="lomake">
          <div className="contest">
            <h3>
              Täytä tietosi ja olet mukana arvonnassa. <br />
              Voit voittaa Stanley‑lounassetin ja kahvit koko kevääksi.
            </h3>
            <StaticImage src="../assets/mukit.png" alt="Osallistu arvontaan" />
          </div>
          <br />
          <br />
          <label>
            Nimi
            <input
              type="text"
              required
              onChange={e => setData({ ...data, nimi: e.target.value })}
            />
          </label>
          <label>
            Sähköpostiosoite
            <input
              type="email"
              required
              onChange={e => setData({ ...data, email: e.target.value })}
            />
          </label>
          <label>
            Puhelinnumero
            <input
              type="tel"
              required
              onChange={e => setData({ ...data, puhelin: e.target.value })}
            />
          </label>
          <label>
            Katuosoite
            <input
              type="text"
              required
              onChange={e => setData({ ...data, osoite: e.target.value })}
            />
          </label>
          <label>
            Postinumero
            <input
              type="text"
              required
              onChange={e => setData({ ...data, postinro: e.target.value })}
            />
          </label>
          <label>
            Postitoimipaikka
            <input
              type="text"
              required
              onChange={e =>
                setData({ ...data, postitoimipaikka: e.target.value })
              }
            />
          </label>
          <label className="flex">
            <p>
              {" "}
              Olen lukenut ja hyväksyn{" "}
              <a href="/ehdot" target="_blank">
                kilpailun säännöt ja ehdot
              </a>
            </p>
            <input
              type="checkbox"
              required
              onChange={e =>
                setData({ ...data, hyvaksynEhdot: e.target.value })
              }
            />
          </label>
          <button type="submit">Osallistu arvontaan</button>
          {error && <span> Jotain meni vikaan!</span>}
        </form>
      ) : (
        <>
          <h3>Kiitos osallistumisestasi!</h3>
          <p>
            Onnea arvontaan – ilmoitamme voittajille henkilökohtaisesti. <br />
          </p>
          <a className="button" href="https://www.lofbergs.fi/tuotteet/">
            Siirry tutustumaan tuotteisiimme
          </a>
        </>
      )}
    </div>
  )
}

export default Form
