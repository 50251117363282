import * as React from "react"
import Layout from "../components/layout"
import Form from "../components/from"
import Seo from "../components/seo"

const Lomake = () => (
  <Layout>
    <Seo title="Arvonta on päättynyt" />
    {/* <Form /> */}
    <h2>Arvonta on päättynyt</h2>
  </Layout>
)

export default Lomake
